import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import MuiTimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { CircularProgress, FormControlLabel, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles, styled, withStyles } from "@mui/styles";
import moment from "moment";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import emailIn from "../../assest/icons/images/icons/email_in.png";
import emailOut from "../../assest/icons/images/icons/email_out.png";
import whatsAppIn from "../../assest/whatsAppIncoming.svg";
import whatsAppOut from "../../assest/whatsAppOutgoing.svg";
import SendEmailPopup from "../../pages/new-ticket/SendEmailPopup";
import { getAttachmentFile } from "../../redux/actions/userManagement/agents";
import timeSpanToReadbleFormat from "../../util/timeSpanToReadbleFormat";
import { ConvertZ, truncate } from "../helpers/helperFunctions";
import BasicButton from "./button/BasicButton";
import CustomChip from "./chip/CustomChip";
import CustomDialog from "./dialog/CustomDialog";
import CustomLoader from "./loader/CustomLoader";
// import TimelineLogItem from "../../pages/history/TimelineItem";
import LockIcon from "@mui/icons-material/Lock";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { UpdateTimeLineLogs } from "../../services/timeline/timeline.service";
import { getFileNameAndExtension } from "../EmailTemplates/AddAttachmentBlobWithPreview";
import { OutgoingEmailIcon, IncomingEmailIcon } from "./svgIcons/chartIcon";

const theme = createTheme();
const  publicIcon = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M12,2C8.13,2 5,5.13 5,9V11H3V19H21V11H19V9C19,5.13 15.87,2 12,2M12,4C14.21,4 16,5.79 16,8H8C8,5.79 9.79,4 12,4M12,14C10.34,14 9,15.34 9,17V18H15V17C15,15.34 13.66,14 12,14Z" /></svg>')`;
const privateIcon = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M12,2C8.13,2 5,5.13 5,9V11H3V19H21V11H19V9C19,5.13 15.87,2 12,2M12,4C14.21,4 16,5.79 16,8H8C8,5.79 9.79,4 12,4M12,14C10.34,14 9,15.34 9,17V18H15V17C15,15.34 13.66,14 12,14Z" /><line x1="5" y1="5" x2="19" y2="19" stroke="red" stroke-width="2" /></svg>')`;

const Android12Switch = styled(Switch)(
  ({ theme, isPrivate }: { theme: any; isPrivate: boolean }) => ({
    padding: 9,
    "& .MuiSwitch-track": {
      backgroundColor: isPrivate ? "red" : "green",
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 13,
        height: 13,
        backgroundSize: "contain",
      },
      "&::before": {
        backgroundImage: isPrivate ? privateIcon : publicIcon,
        left: 12,
      },
      "&::after": {
        backgroundImage: isPrivate ? privateIcon : publicIcon,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 20,
      height: 20,
      // margin: '2px 4px',
    },
  })
);


const UpdateTimeLineLogsFunc = async (id: any, value: any) => {
  let res = await UpdateTimeLineLogs(id,value);
  return res;
};

const Android12SwitchRender = ({id,value}:{id: any,value: any}) => {
  const [isNotePrivate, setIsPrivateNote] = React.useState(value); 
  const [isLoading, setIsLoading] = React.useState(false); 

  React.useEffect(()=>{
    setIsPrivateNote(value)
  },[value])

  const handleChange = async (event: any) => {
    setIsLoading(true); 
    let ress: any = false
    UpdateTimeLineLogsFunc(id, event.target.checked)
      .then((res: any) => {
        console.log("UpdateTimeLineLogsFunc", res);
        setIsPrivateNote(!event.target.checked);
      })
      .finally(() => {
        setIsLoading(false); 
      });
     
  };
  
  return (
    <>
      {" "}
      <Tooltip  title={`${isNotePrivate ? "Private Note" : "Public Note"}`} placement="right-start">
      <div style={{display:"flex", flexDirection:"row", gap:5}}>
      <Android12Switch
        sx={{ position: "absolute", left: 28, top: -10 }}
        isPrivate={isNotePrivate}
        onChange={handleChange}
        checked={isNotePrivate}
      />
       {isLoading && <CircularProgress size={10} sx={{ position: "absolute", left: 80, top: 4 }} />}
      </div>
      </Tooltip>

    </>
  );
};

const TimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
})(MuiTimelineItem);

const useStyles = makeStyles(() =>
  createStyles({
    Box: {
      fontFamily: "'DM Sans', sans-serif !important",

      // background: "green !important",
      height: "120px",
      marginBottom: "40px",
    },
    Avatar: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      "&::before": {
        content: '""',
      },
    },
    Line: {
      "&::before": {
        content: '""',
        width: "200px",
        height: "0.5px",
        background: "#191970",
        left: "150px",
        top: "26%",
        position: "absolute",
      },
    },
    VLine: {
      borderLeft: "1px solid #ddd",
    },
    TimelineDesc: {
      borderLeft: "1px solid #ddd",
    },
    TimelineDescAct: {
      borderLeft: "1px solid #ddd",
    },
  })
);
const getAttchmentFun = async (id: any) => {
  let getAttchement: any = await getAttachmentFile(id);
  return getAttchement;
};
const fun = (id: any, type: any, key: any, status: any) => {
  let temp = key == "status" ? status?.status : status.agent;
  let statusTemp = temp?.find((x: any) => x?.id === id);
  type == "color" && console.log("fun fun fun", statusTemp?.name);
  return type == "color" ? statusTemp?.color :statusTemp?.displayName
};
const funForFinding = (id: any, status: any, type: any) => {
  let temp =
    type == "Agent"
      ? status.agent
      : type == "Contact"
      ? status?.contactList
      : status?.agent;
  let statusTemp = temp?.find((x: any) => x?.id === id);
  return statusTemp?.name ? statusTemp?.name : null;
};
const fun2 = (id: any, status: any) => {
  let temp = status?.source;
  let statusTemp = temp?.find((x: any) => x?.id === id);
  return statusTemp?.name == undefined ? "null" : statusTemp?.name;
};

const generateIcon = (outgoingSourceCode: any, sourceCode: any) => {
  if (outgoingSourceCode) {
    if (outgoingSourceCode == "WhatsApp") {
      return whatsAppOut;
    } else if (outgoingSourceCode == "Email") {
      return emailOut;
    } else {
      return "";
    }
  } else {
    if (sourceCode == "WhatsApp") {
      return whatsAppIn;
    } else if (sourceCode == "Email") {
      return emailIn;
    } else {
      return "";
    }
  }
};

function convertDateFormat(inputDate: any) {
  const parsedDate = new Date(inputDate);

  if (isNaN(parsedDate.getTime())) {
    return "Invalid Date";
  }

  const year = parsedDate.getFullYear();
  const month = ("0" + (parsedDate.getMonth() + 1)).slice(-2);
  const day = ("0" + parsedDate.getDate()).slice(-2);
  const hours = ("0" + (parsedDate.getHours() % 12 || 12)).slice(-2);
  const minutes = ("0" + parsedDate.getMinutes()).slice(-2);
  const seconds = ("0" + parsedDate.getSeconds()).slice(-2);

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

  return formattedDate;
}

function parseDateString(inputString: any) {
  const inputTimeString = inputString;

  const dateRegex = /\{([^}]+)\}/g;

  const resultString = inputTimeString.replace(
    dateRegex,
    (match: any, dateStr: any) => {
      const convertedDate = convertDateFormat(dateStr);

      console.log("outputTime", inputString, convertedDate);

      const localTime = moment(convertedDate).format("L LT");
      // const localTime = moment(utcDate).format("L LT");
      return localTime;
    }
  );

  return resultString;
}

function getLatestUpdate(objectsArray: any) {
  console.log("getLatestUpdate", objectsArray);
  // const updateObjects = objectsArray?.filter(
  //   (obj: any) => obj.type === "Update"
  // );

  // if (updateObjects?.length === 0) {
  //   return null;
  // }

  return null;
}

export default function BasicTimeline({
  handleClick,
  data,
  isTestFlag,
  fetchNextWeek,
  hasMore,
  status,
  type,
  setData,
}: {
  handleClick: any;
  data: any;
  isTestFlag: boolean;
  fetchNextWeek: any;
  hasMore: any;
  status: any;
  type: any;
  setData: any;
}) {
  console.log("custom time line", status.contactList);

  const classes = useStyles();
  const [isSendEmailPopup, setIsSendEMailPopup] = React.useState<any>(false);
  const [selectedEmail, setSelectedMail] = React.useState<any>(false);
  const [isShowticketLogChanges, setIsShowticketLogChanges] =
    React.useState<any>(false);
  const [progress, setProgress] = React.useState<any>({
    id: "",
    status: false,
  });
  
  return (
    <>
      {isShowticketLogChanges && (
        <CustomDialog
          heading={`Change log`}
          body={
            <>
              <div>
                {selectedEmail?.ticketLogChangesList.length > 0 ? (
                  selectedEmail?.ticketLogChangesList?.map((item: any) => {
                    return (
                      <ul>
                        <li>{parseDateString(item?.changes)}</li>
                      </ul>
                    );
                  })
                ) : (
                  <p>There is no changes made</p>
                )}
              </div>
            </>
          }
          open={isShowticketLogChanges}
          handleClose={() => {
            setIsShowticketLogChanges(false);
          }}
          footer={
            <>
              {" "}
              <BasicButton
                onClick={() => {
                  setIsShowticketLogChanges(false);
                }}
                type="submit"
                label={"OK"}
                isLoading={false}
                isDisabled={false}
                color="primary"
                size="large"
                variant={"contained"}
                endIcon={false}
              />
            </>
          }
        />
      )}
      {isSendEmailPopup && (
        <SendEmailPopup
          setEditData={false}
          filterEmailData={false}
          type="history"
          isSendEmailPopup={isSendEmailPopup}
          setIsSendEMailPopup={setIsSendEMailPopup}
          submittedData={selectedEmail}
          setData={setData}
        />
      )}

      {/* <div>Title: {item.type=="Update" ? item} </div> */}
      {getLatestUpdate(data?.logData)}
      <Timeline>
        {isTestFlag ? (
          <InfiniteScroll
            dataLength={data?.logData?.length ? data?.logData?.length : 0}
            next={fetchNextWeek}
            hasMore={hasMore}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>End of Log - no more data to display</b>
              </p>
            }
            loader={<h4>Loading...</h4>}
          >
            {data?.logData?.map((item: any, index: any) => {
              let temp = index + 1;
              // console.log('data[index]', data[temp], index+1)
              return (
                <>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      onClick={() => {
                        handleClick(index);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // justifyContent: "space-between",

                          // margin:"auto"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginRight: "35px",
                            flexDirection: "row-reverse",
                            textTransform: "capitalize",
                            width: "300px",
                            wordBreak: "break-word",
                          }}
                        >
                          {item?.type !== "Update" && type == "long" && (
                            <div
                              className="timeline-image"
                              style={{
                                paddingRight: "0px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let temp = generateIcon(
                                  item.outgoingSourceCode,
                                  item.sourceCode
                                );
                                if (temp) {
                                  setIsSendEMailPopup(true);
                                  setSelectedMail(item);
                                }
                              }}
                            >
                              <>
                                <div>
                                {(item?.outgoingSourceCode == "Email" || item?.sourceCode == "Email") ? <>
                                  {item?.type === "Outgoing" ? <OutgoingEmailIcon color={item?.isMessageSuccess ? "#09a44d" : "#ea0900"} /> : <IncomingEmailIcon />}
                                  </> :
                                    <img
                                      style={{ width: "20px" }}
                                      src={generateIcon(
                                        item.outgoingSourceCode,
                                        item.sourceCode
                                      )}
                                    />
                                  }
                                </div>
                              </>
                            </div>
                          )}
                          {item?.ticketLogChangesList?.length > 0 && (
                            <div
                              onClick={() => {
                                setIsShowticketLogChanges(true);
                                setSelectedMail(item);
                              }}
                            >
                              <ChangeHistoryIcon
                                sx={{
                                  color: "red",
                                  paddingRight: "5px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          )}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textTransform: "capitalize",
                              width: "400px",
                            }}
                          >
                            {item.type == "Update" ? (
                              <>
                                <ShowMorefunForHeading textData={(item?.note || "")} />
                              </>
                            ) : (
                              <>
                                <ShowMorefunForHeading textData={item?.title} />
                              </>
                            )}

                            <div
                              className="timeline-data"
                              style={{
                                color: "black",
                                fontSize: "12px",
                                marginTop: "10px",
                              }}
                            >
                              {/* {moment(item?.createdDateTime).fromNow()}
                        {moment(item?.createdDateTime).format("L LTS")} */}
                              {moment
                                .utc(item?.createdDateTime)
                                .local()
                                .fromNow()}
                            </div>
                            <div
                              className="timeline-data"
                              style={{
                                color: "black",
                                fontSize: "12px",
                                marginTop: "6px",
                              }}
                            >
                              {moment(item?.createdDateTime + "Z").format(
                                "L LT"
                              )}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "auto",
                                height: "auto",
                                gap: 10,
                                // paddingLeft: "10px",
                              }}
                            >
                              {item?.ticketLogAttachmentsList?.map(
                                (item: any, index1: any) => {
                                  return (
                                    <>
                                      <div
                                        onClick={() => {
                                          let a: any = {
                                            id: item?.id,
                                            status: false,
                                          };
                                          setProgress(a);
                                          if (item.attachmentPath) {
                                            window.open(
                                              item.attachmentPath,
                                              "_blank"
                                            );
                                          }
                                        }}
                                      >
                                        <CustomChip
                                          icon={
                                            item?.id === progress?.id ? (
                                              progress?.status == true ? (
                                                <CustomLoader />
                                              ) : (
                                                <>
                                                  {getFileNameAndExtension(item?.attachmentPath).fileExtension == "pdf" ? (
                                                    <PictureAsPdfIcon
                                                      sx={{
                                                        width: "30px",
                                                        height: "30px",
                                                        color: "#2F80ED",
                                                      }}
                                                    />
                                                  ) : (
                                                    <ImageIcon
                                                      sx={{
                                                        width: "50px",
                                                        height: "30px",
                                                        color: "#2F80ED",
                                                      }}
                                                    />
                                                  )}
                                                </>
                                              )
                                            ) : (
                                              <>
                                                {getFileNameAndExtension(item?.attachmentPath).fileExtension== "pdf" ? (
                                                  <PictureAsPdfIcon
                                                    sx={{
                                                      width: "30px",
                                                      height: "30px",
                                                      color: "#2F80ED",
                                                    }}
                                                  />
                                                ) : (
                                                  <ImageIcon
                                                    sx={{
                                                      width: "50px",
                                                      height: "30px",
                                                      color: "#2F80ED",
                                                      // position:"absolute",
                                                      // left:10
                                                    }}
                                                  />
                                                )}
                                              </>
                                            )
                                          }
                                          label={getFileNameAndExtension(item?.attachmentPath).filename}
                                          color="#0A93FC"
                                          bg="white"
                                          transform={"none"}
                                          variant="outlined"
                                        />
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "start",
                            gap: 10,
                            // margin: "auto",
                            width: "325px",
                            fontSize: "12px",
                            marginRight: "10px",
                            minWidth: "325px",
                          }}
                          className={classes.VLine}
                        >
                          <Tooltip
                            title={`Action By : ${funForFinding(
                              item?.createdByUserId,
                              status,
                              item?.userType
                            )}`}
                          >
                            <Avatar
                              className="timeline-avatar"
                              sx={{
                                bgcolor: "#2F80ED",
                                marginLeft: "10px",
                                width: "30px",
                                height: "30px",
                                fontSize: "12px",
                              }}
                              variant="rounded"
                            >
                              {
                                funForFinding(
                                  item?.createdByUserId,
                                  status,
                                  item?.userType
                                )?.[0]
                              }
                            </Avatar>
                          </Tooltip>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 0,
                            }}
                          >
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: 700,
                                margin: 0,
                                maxWidth: "48px",
                              }}
                            >
                              {funForFinding(
                                item?.createdByUserId,
                                status,
                                item?.userType
                              )}
                            </p>
                            <p style={{ margin: 0 }}>{`(${item?.userType})`}</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 0,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 8,
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: "600",
                                  margin: 0,
                                  wordBreak: "inherit",
                                }}
                              >
                                First Action date:{" "}
                              </p>
                              {item?.firstResponseDueDateTime
                                ? ConvertZ(item?.firstResponseDueDateTime)
                                : "---"}
                              {/* {ConvertZ(
                                item?.firstResponseDueDateTime + "Z"
                              )} */}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 10,
                                alignItems: "center",
                              }}
                            >
                              <p style={{ fontWeight: "600", margin: 0 }}>
                                Status:{" "}
                              </p>
                              {/* Changed  */}
                              <CustomChip
                                icon={false}
                                label={fun(
                                  item?.statusId,
                                  "none",
                                  "status",
                                  status
                                )}
                                color={"white"}
                                bg={fun(
                                  item?.statusId,
                                  "color",
                                  "status",
                                  status
                                )}
                                variant={"none"}
                                transform={"none"}
                              />
                              {/* to
                             <CustomChip
                              icon={false}
                              label={fun(data[index].status, "none", "status")}
                              color={"white"}
                              bg={fun(item?.status, "color", "status")}
                              variant={"none"}
                              transform={"none"}
                            /> */}
                            </div>
                            <div>
                              <span style={{ fontWeight: "600", margin: 0 }}>
                                Owner :{" "}
                              </span>
                              {fun(item?.agentId, "none", "agent", status)}
                            </div>
                            <div>
                              <span style={{ fontWeight: "600", margin: 0 }}>
                                Effort:{" "}
                              </span>
                              <span
                                className="timeline-effort"
                                style={{ color: "#2F80ED" }}
                              >
                                {timeSpanToReadbleFormat(
                                  item?.effort,
                                  "effort"
                                )}
                              </span>
                            </div>
                            <div>
                              <span style={{ fontWeight: "600", margin: 0 }}>
                                Downtime:{" "}
                              </span>
                              <span
                                className="timeline-effort"
                                style={{ color: "#2F80ED" }}
                              >
                                {timeSpanToReadbleFormat(
                                  item?.downtime,
                                  "downtime"
                                )}
                              </span>
                            </div>
                            {item?.ticketAssetList?.length > 0 &&
                              (fun(item?.status, "none", "status", status) ==
                                "Resolved" ||
                                fun(item?.status, "none", "status", status) ==
                                  "Closed") && (
                                <div>
                                  <span
                                    style={{ fontWeight: "600", margin: 0 }}
                                  >
                                    Downtime:{" "}
                                  </span>
                                  <span style={{ color: "#2F80ED" }}>
                                    {timeSpanToReadbleFormat(
                                      item?.downtime,
                                      "downtime"
                                    )}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>

                        {/* <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            gap: 3,
                            fontSize: "12px",
                            width: "300px",
                            padding: "0px 10px 0px 10px ",
                          }}
                          className={classes.TimelineDesc}
                        >
                          <p style={{ fontWeight: "600", margin: 0 }}>
                            First Action Desc:{" "}
                          </p>

                          <p
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {item?.nextActionDescription}
                          </p>
                        </div> */}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: 3,
                            fontSize: "12px",
                            padding: "0px 10px 0px 10px ",
                          }}
                          className={`${classes.TimelineDescAct} Timeline-Note`}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "flex-end",
                              gap: 5,
                              position: "relative",
                            }}
                          >
                            <p style={{ fontWeight: "600" }}>
                              {fun(item?.statusId, "none", "status", status) ==
                              "Resolved"
                                ? "Solution:"
                                : index == data?.logData?.length - 1
                                ? "Description"
                                : "Note"}
                            </p>
                            {fun(item?.statusId, "none", "status", status) !==
                              "Resolved" &&
                              index !== data?.logData?.length - 1 && (
                                <Android12SwitchRender id={item.id} value={item?.isNotePrivate}
                                />
                              )}
                          </div>

                          <ShowMorefun
                            textData={
                              fun(item?.statusId, "none", "status", status) ==
                              "Resolved"
                                ? (item?.note || "")
                                : index == data?.logData?.length - 1
                                ? item?.description
                                : (item?.note || "")
                            }
                          />
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                </>
              );
            })}
          </InfiniteScroll>
        ) : (
          data?.logData?.map((item: any, index: any) => {
            return (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  onClick={() => {
                    handleClick(index);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textTransform: "capitalize",
                      }}
                    >
                      <div>{item.title}</div>
                      <div style={{ color: "black", fontSize: "12px" }}>
                        {moment(data?.logData?.updatedDateTime).format("L LT")}{" "}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                        width: "150px",
                        fontSize: "12px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: "#2F80ED" }} variant="rounded">
                        {item?.user?.name[0]}
                      </Avatar>
                      <p style={{ fontSize: "16px", fontWeight: 700 }}>
                        {item?.user?.name}
                      </p>
                    </div>
                  </div>
                </TimelineContent>
              </TimelineItem>
            );
          })
        )}
      </Timeline>
    </>
  );
}

export const ShowMorefun = ({ textData }: { textData: any }) => {
  const [showMore, setShowMore] = React.useState(false);
  return (
    <>
      <p className="pre-wrap-txt" style={{ textTransform: "capitalize", wordBreak: "break-all" }}>
        {showMore ? textData : `${truncate(textData)}`}
        {textData?.length > 100 && (
          <a
            onClick={() => setShowMore(!showMore)}
            style={{ color: "#2F80ED", cursor: "pointer" }}
          >
            {showMore ? " (Show less)" : " (Show more)"}
          </a>
        )}
      </p>
    </>
  );
};
export const ShowMorefunForHeading = ({ textData }: { textData: any }) => {
  const [showMore, setShowMore] = React.useState(false);
  return (
    <>
      <div
        style={{
          textTransform: "capitalize",
          wordBreak: "break-all",
          marginRight: "13px",
          fontSize: "12px",
        }}
      >
        {showMore ? textData : `${truncate(textData)}`}
        {/* {textData?.length > 100 && (
         <a
           onClick={() => setShowMore(!showMore)}
           style={{ color: "#2F80ED", cursor: "pointer" }}
         >
           {showMore ? " Show less" : " Show more"}
         </a>
        )} */}
      </div>
    </>
  );
};
